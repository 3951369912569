input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

:root {
    --primary: #BE9D67;
    --black: #1f1f1f;
    --danger: #a40f0f;
    --success: green;
    --blackDarker: #131313;
    --font-size: 16px;
}

* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

.mainContainer {
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: #2c2c2c;
}