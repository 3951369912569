.button {
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.primary {
    background-color: var(--primary);
}

.danger {
    background-color: var(--danger);
}