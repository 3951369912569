.input {
    border: 0;
    border-bottom: 2px solid var(--primary);
    background-color: transparent;
}

.input:focus {
    outline: none;
}

.input-number {
    text-align: center;
    width: 50px;
    color: var(--primary);
    font-size: 2rem;
}