.tableHeader {
    background-color: var(--blackDarker);
    color: white;
    font-weight: bolder;
}

th {
    color: white;
}

td {
    color: white;
}

td,
th {
    font-size: 1rem;
    text-align: center;
    padding: 0.5rem;
    font-weight: 600;
}

th {
    font-size: 1.2rem;
}

tr {
    & td {
        background-color: var(--black);
        border-bottom: 1px solid #494949;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
}

.tableContainer {
    width: 100%;
    border-radius: 10px;
    overflow-y: none;
    overflow-x: auto;
    border: 3px solid var(--blackDarker);
}

.text-primary {
    color: var(--primary);
}