.inputContainer {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
    align-items: center;
}

.title {
    text-align: center;
    font-size: 2rem;
    color: var(--primary);
    font-weight: bolder;
    margin-bottom: 2rem;
}

.homeContainer {
    justify-content: center;
    flex-direction: column;
    padding: 10px 15px 10px 10px;
    overflow-x: hidden;
    width: 100vw;
}

.maxNumberContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
    gap: 1rem;
}

.maxNumberSuperContainer {
    text-align: center;
    padding-bottom: 0.25rem;
    color: var(--primary);
    font-weight: bold;
}

.maxNumberIcon {
    font-size: 1.5rem;
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 1rem;
    padding: 0.5rem;
}

.box-sub-container {
    margin-bottom: 0 !important;
    padding-top: 1rem !important;
    text-align: center;
}

.box-number-gold {
    margin-bottom: 0 !important;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primary);
}

.box-container{
    display: flex;
    margin-top: 1rem;
    padding: 1rem 0;
    justify-content: center;
    background-color: var(--black);
    align-items: center;
    text-align: center;
    border: 2px solid black;
    border-radius: 1rem;
    margin-bottom: 0 !important;
    gap: 1rem;

    .box-sub-container-2 {
        padding: 0.25rem;
    }

    .box-header {
        font-size: 1rem;
        padding: 0.25rem;
        background-color: transparent;
    }

    .box-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        /*grid-template-columns: repeat(4, minmax(0, 1fr));*/
        padding: 0.25rem;
        justify-content: center;
        color: white;

        p {
            font-size: 0.9rem;
            border-radius: 0.5rem;
            padding: 0 0.15rem;
            border: 1px solid white;
        }
    }

    .box {
        justify-content: space-between;
        gap: 1.5rem;
        font-size: 1.25rem;
        align-items: center;
        text-wrap: auto;
        color: white;
        padding: 0.5rem 0;
        display: flex;

        p {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 0.25rem;
        }
    }

    .box-number {
        margin-bottom: 0 !important;
        padding: 1rem;
        color: white;
    }
}

.box {
    display: flex !important;
}

.message {
    color: var(--danger);
    font-weight: bolder;;
    text-align: center;
}

.message-info {
    color: white;
    font-size: 10px;
    text-align: center;
}

.success-icon {
    color: var(--success);
}

.danger-icon {
    color: var(--danger);
}

.casesCount {
    font-size: 2rem;
    color: white;
    padding-top: 1rem;
    text-align: center;

}